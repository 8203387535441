import React, {useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";

function Nav({isShow}){
    const location = useLocation();
    const [activeMenu, setActiveMenu] = useState(null);

    const toggleMenu = (menuName) => {
        if (activeMenu === menuName) {
            setActiveMenu(null); // Close the menu if it's already open
        } else {
            setActiveMenu(menuName); // Open the selected menu
        }
    };

    const handleReload = () => {
        if (location.pathname === '/franchisee') {
            window.location.reload();
        }
    };


    return(
        <div className={`vertical-menu ${isShow ? 'd-block' : 'd-none'}`}>
            <div data-simplebar className="h-100">
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li className="menu-title" key="t-menu">Menu</li>

                        <li className={location.pathname === '/dashboard' ? 'mm-active' : ''}>
                            <Link to="/dashboard" className="waves-effect">
                                <i className="bx bx-home-circle"></i>
                                <span key="t-dashboards">Dashboards</span>
                            </Link>
                        </li>

                        {/* Franchisee Menu */}
                        <li className={location.pathname === '/franchisee' ? 'mm-active' : ''}>
                            <Link to="/franchisee" className="waves-effect" onClick={handleReload}>
                                <i className="bx bx-store"></i>
                                <span>Franchisee</span>
                            </Link>
                        </li>
                        {/*<li>
                            <a
                                href="#!"
                                className="waves-effect"
                                onClick={() => toggleMenu('franchisee')}
                                aria-expanded={activeMenu === 'franchisee'}
                            >
                                <i className="bx bx-store"></i>
                                <span>Franchisee</span>
                            </a>
                            <ul className={`sub-menu collapse ${activeMenu === 'franchisee' ? 'show' : ''}`}>
                                <li className={location.pathname === '/franchisee' ? 'mm-active' : ''}>
                                    <Link to="/franchisee">Global Franchisee</Link>
                                </li>
                                <li className={location.pathname === '/spain-franchisee' ? 'mm-active' : ''}>
                                    <Link to="/spain-franchisee">Spain Franchisee</Link>
                                </li>
                                <li className={location.pathname === '/uk-franchisee' ? 'mm-active' : ''}>
                                    <Link to="/uk-franchisee">UK Franchisee</Link>
                                </li>
                            </ul>
                        </li>*/}

                        {/* Distributor Menu */}
                        <li className={location.pathname === '/distributor' ? 'mm-active' : ''}>
                            <Link to="/distributor" className="waves-effect">
                                <i className=""><img src="../../assets/images/tub-icon.svg" alt="Distributor" width={23}/></i>
                                <span>Distributor</span>
                            </Link>
                        </li>

                        {/* Aqua Menu */}
                        <li className={location.pathname === '/aqua-global-franchisee' ? 'mm-active' : ''}>
                            <Link to="/aqua-global-franchisee" className="waves-effect">
                                <i className="bx bx-shield"></i>
                                <span>Aqua Franchisee</span>
                            </Link>
                        </li>

                        {/* Gold Menu */}
                        <li className={location.pathname === '/gold-global-franchisee' ? 'mm-active' : ''}>
                            <Link to="/gold-global-franchisee">
                                <i className="bx bx-diamond"></i>
                                <span>Gold Franchisee</span>
                            </Link>
                        </li>
                        {/*<li>
                            <a
                                href="#!"
                                className="waves-effect"
                                onClick={() => toggleMenu('aqua')}
                                aria-expanded={activeMenu === 'aqua'}
                            >
                                <i className="bx bx-shield"></i>
                                <span>Aqua</span>
                            </a>
                            <ul className={`sub-menu collapse ${activeMenu === 'aqua' ? 'show' : ''}`}>
                                <li className={location.pathname === '/aqua-global-franchisee' ? 'mm-active' : ''}>
                                    <Link to="/aqua-global-franchisee">Global Franchisee</Link>
                                </li>
                                <li className={location.pathname === '/aqua-spain-franchisee' ? 'mm-active' : ''}>
                                    <Link to="/aqua-spain-franchisee">Spain Franchisee</Link>
                                </li>
                                <li className={location.pathname === '/aqua-uk-franchisee' ? 'mm-active' : ''}>
                                    <Link to="/aqua-uk-franchisee">UK Franchisee</Link>
                                </li>
                            </ul>
                        </li>*/}

                        {/* Gold Menu */}
                        {/*<li>
                            <a
                                href="#!"
                                className="waves-effect"
                                onClick={() => toggleMenu('gold')}
                                aria-expanded={activeMenu === 'gold'}
                            >
                                <i className="bx bx-diamond"></i>
                                <span>Gold</span>
                            </a>
                            <ul className={`sub-menu collapse ${activeMenu === 'gold' ? 'show' : ''}`}>
                                <li className={location.pathname === '/gold-global-franchisee' ? 'mm-active' : ''}>
                                    <Link to="/gold-global-franchisee">Global Franchisee</Link>
                                </li>
                                <li className={location.pathname === '/gold-spain-franchisee' ? 'mm-active' : ''}>
                                    <Link to="/gold-spain-franchisee">Spain Franchisee</Link>
                                </li>
                                <li className={location.pathname === '/gold-uk-franchisee' ? 'mm-active' : ''}>
                                    <Link to="/gold-uk-franchisee">UK Franchisee</Link>
                                </li>
                            </ul>
                        </li>*/}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Nav;