import React, {useEffect, useState} from "react";
import axios from "../../axios";
import {showToast} from "../ToastHelper";
import Loader from "../Loader";

function SendToMFMail({id, countryCode, countryOfInterest}) {
    const [isMailSent, setIsMailSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isCountryAvailable, setIsCountryAvailable] = useState(false);
    const [countryCodeToSend, setCountryCodeToSend] = useState(null);

    const countries = [
        { name: "Brunei", code: "BN" },
        { name: "Cambodia", code: "KH" },
        { name: "Canada", code: "CA" },
        { name: "Costa Rica", code: "CR" },
        { name: "Chile", code: "CL" },
        { name: "Egypt", code: "EG" },
        { name: "España", code: "ES" },
        { name: "Ghana", code: "GH" },
        { name: "Guatemala", code: "GT" },
        { name: "Indonesia", code: "ID" },
        { name: "Malaysia", code: "MY" },
        { name: "Mexico", code: "MX" },
        { name: "Malta", code: "MT" },
        { name: "Portugal", code: "PT" },
        { name: "Peru", code: "PE" },
        { name: "Philippines", code: "PH" },
        { name: "Singapore", code: "SG" },
        { name: "Saudi Arabia", code: "SA" },
        { name: "Thailand", code: "TH" },
        { name: "United Kingdom", code: "GB" },
        { name: "United Arab Emirates", code: "AE" },
    ];

    useEffect(() => {
        // Check if the country of interest is available in the list
        const countryExists = countries.some(
            (country) => country.name.toLowerCase() === countryOfInterest?.toLowerCase()
        );
        setIsCountryAvailable(countryExists);

        // Get the corresponding country code
        const foundCountry = countries.find(
            (country) => country.name.toLowerCase() === countryOfInterest?.toLowerCase()
        );
        setCountryCodeToSend(foundCountry ? foundCountry.code : null);

    }, [countryOfInterest]);

    const handleSendToMF = async () => {
        if (!isCountryAvailable || !countryCodeToSend) return; // Check if country code is available

        try {
            setIsMailSent(true);
            setIsLoading(true);
            const response = await axios.post(`email/sendtomf/${id}`, {
                forward_to_mf: countryCodeToSend,
                country: countryOfInterest
            });

            if (response.data.status === 'success') {
                showToast(response.data.message, 'success');
            }else {
                showToast(response.data.message, 'error');
            }
        } catch (error) {
            showToast(error.response.data.message, 'error');
        }finally {
            setIsMailSent(false);
            setIsLoading(false);
        }
    };

    return(
        <>
            {isLoading && <Loader/>}
            <button
                className="btn btn-sm btn-outline-secondary btn-thick"
                onClick={handleSendToMF}
                disabled={!isCountryAvailable}
                style={{marginRight: '5px'}}
            >
                {isMailSent ? 'Sending Mail...' : 'Send To MF'}
            </button>
        </>
    )
}

export default SendToMFMail;