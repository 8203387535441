import React, {useEffect, useState} from "react";
import axios from "../axios";
import {toast} from "react-toastify";
import Loader from "../components/Loader";


function Dashboard() {
    const [loading, setLoading] = useState(false);
    const [totalFranchiseeRecords, setFranchiseeRecords] = useState(0);
    const [totalDistributorRecords, setDistributorRecords] = useState(0);
    const [countFranchiseByCountry, setCountFranchiseByCountry] = useState([]);
    const [countFranchiseRequest, setCountFranchiseRequest] = useState([]);
    const [countEmailStatus, setEmailStatus] = useState('');
    const [notViewFranchise, setNotViewFranchise] = useState(null);

    useEffect(() => {
        fetchTotalRecord();
    }, []);

    const fetchTotalRecord = () => {
        setLoading(true);
        axios.get(`/get_dashboard_details`)
            .then((response) => {
                setFranchiseeRecords(response.data.total_franchisees);
                setDistributorRecords(response.data.total_distributors);
                setCountFranchiseByCountry(response.data.franchisee_counts_by_country);
                setCountFranchiseRequest(response.data.mainInterestCounts);
                setEmailStatus(response.data.emailStatusCounts);
                setNotViewFranchise(response.data.notViewedFranchiseesCount);
            })
            .catch((error) => {
                toast.error('Something went wrong.');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return(
        <>
            {loading && <Loader/>}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0 font-size-18">Dashboard</h4>
                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><a href="#">Dashboards</a></li>
                                    <li className="breadcrumb-item active">Dashboard</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card mini-stats-wid ibox-fullheight">
                                    <div className="card-body fr-body">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium" style={{fontSize: '24px'}}>Total
                                                    Franchisee</p>
                                                <h1 className="mb-0"
                                                    style={{fontWeight: 'bold'}}>{totalFranchiseeRecords}</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card mini-stats-wid">
                                    <div className="card-body fr-body">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium"
                                                   style={{fontSize: '24px'}}>Total
                                                    Distributors</p>
                                                <h1 className="mb-0"
                                                    style={{fontWeight: 'bold'}}>{totalDistributorRecords}</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card mini-stats-wid">
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium"
                                                   style={{fontSize: '24px'}}>Unread</p>
                                                <h1 className="mb-0"
                                                    style={{fontWeight: 'bold'}}>{notViewFranchise}</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card mini-stats-wid">
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium"
                                                   style={{fontSize: '24px'}}>Replied</p>
                                                <h1 className="mb-0"
                                                    style={{fontWeight: 'bold'}}>{countEmailStatus.custom_message_sent_count}</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card mini-stats-wid">
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium"
                                                   style={{fontSize: '24px'}}>Thank You</p>
                                                <h1 className="mb-0"
                                                    style={{fontWeight: 'bold'}}>{countEmailStatus.thank_you_count}</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card mini-stats-wid">
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium"
                                                   style={{fontSize: '24px'}}>Rejected</p>
                                                <h1 className="mb-0"
                                                    style={{fontWeight: 'bold'}}>{countEmailStatus.sorry_count}</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title mb-4">Candidate Per Country</h4>
                                <div className="table-responsive">
                                    <table className="table align-middle table-nowrap mb-0">
                                        <thead className="table-light">
                                        <tr>
                                            <th className="align-middle">Country</th>
                                            <th className="align-middle">Candidates</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {Object.entries(countFranchiseByCountry).map(([country, count]) => (
                                            <tr key={country}>
                                                <td>{country}</td>
                                                <td>{count}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title mb-4">Franchisee Request</h4>
                                <div className="table-responsive">
                                    <table className="table align-middle table-nowrap mb-0">
                                        <thead className="table-light">
                                        <tr>
                                            <th className="align-middle">Request</th>
                                            <th className="align-middle">Candidates</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {Object.entries(countFranchiseRequest).map(([request, count]) => (
                                            <tr key={request}>
                                                <td>{request}</td>
                                                <td>{count}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Dashboard;