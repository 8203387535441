import React, {useEffect, useRef, useState} from "react";
import {Link, useParams} from "react-router-dom";
import axios from "../../axios";
import Loader from "../../components/Loader";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import generateFranchiseePDF from 'react-to-pdf';
import {showToast} from "../../components/ToastHelper";
import SorryMail from "../../components/emails/SorryMail";
import ThankYouMail from "../../components/emails/ThankYouMail";
import ForwardMail from "../../components/emails/ForwardMail";
import CustomizedMail from "../../components/emails/CustomizedMail";
import SendToMFMail from "../../components/emails/SendToMFMail";

function FranchiseeDetail({ id: propId }) {
    const { id: urlId } = useParams();
    const countryCode = "global";
    const [item, setItem] = useState(null);
    const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const targetRef = useRef();
    const [isCountryExist, setIsCountryExist] = useState(null);
    const [lastEmailStatus, setLastEmailStatus] = useState('');
    const id = propId || urlId;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`get_franchisee/${id}`);
                // console.log('Response', response.data.data);
                setItem(response.data.data);
                setIsCountryExist(response.data.data.country_of_interest);

                // Extract the last email status
                const emailStatuses = response.data.data.email_statuses;
                if (emailStatuses.length > 0) {
                    const lastStatus = emailStatuses[emailStatuses.length - 1].sent_to; // Get the last email status
                    setLastEmailStatus(lastStatus);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (id) {  // Ensure ID is defined before fetching
            fetchData();
        }
    }, [id]);

    if (!item) {
        return <Loader />;
    }

    const options = {
        paperSize: {
            width: '210mm', // Adjust the width as needed
            height: '297mm', // Adjust the height as needed
        },
    };

    const fileName = `Franchisee_${item.first_name}_${item.last_name}.pdf`;

    const handleGeneratePDF = async () => {
        setIsGeneratingPDF(true);

        try {
            await generateFranchiseePDF(targetRef, { filename: fileName });
        } catch (error) {
            console.error('Error generating PDF:', error);
        } finally {
            setIsGeneratingPDF(false);
        }
    };

    return(
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Profile</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><Link to="/franchisee">Franchisee</Link></li>
                                <li className="breadcrumb-item active">{item.first_name}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            {isLoading && <Loader />}
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />

            <div className="row">
                <div className="col-lg-4">
                    {lastEmailStatus && (
                        <p className="mb-0"><strong>Forward To:</strong> {lastEmailStatus}</p>
                    )}
                </div>
                <div className="col-lg-8">
                    <div className="edit-info float-end mb-3">
                        {/*Customized Mail Button*/}
                        <CustomizedMail id={id} countryCode={countryCode} />

                        {/*Forward Mail Button*/}
                        <ForwardMail id={id} countryCode={countryCode} />

                        {/*Send To MF Mail Button*/}
                        <SendToMFMail id={id} countryCode={countryCode} countryOfInterest={isCountryExist} />

                        {/*Thank you Mail Button*/}
                        <ThankYouMail id={id} countryCode={countryCode} />

                        {/*Sorry Mail Button*/}
                        <SorryMail id={id} countryCode={countryCode} />

                        <button className="btn btn-sm btn-outline-info" onClick={handleGeneratePDF}>
                            {isGeneratingPDF ? 'Generating PDF...' : 'Download PDF'}
                        </button>
                    </div>
                </div>
            </div>

            <div className="row" ref={targetRef} style={{backgroundColor: '#2cd5c4'}}>
                <div className="col-xl-12 p-0">
                    <img src="/assets/images/franchise-form-design-top.jpg" className="img-fluid w-100" alt="Franchise Form"/>
                </div>
                <div className="col-xl-12 form-layout p-0">
                    <div className="card">
                        <h4 className="card-title mb-4">Personal Information :</h4>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-striped mb-0">
                                    <tbody>
                                    <tr>
                                        <th scope="row">First Name *</th>
                                        <td>{item.first_name}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Last Name *</th>
                                        <td>{item.last_name}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Mobile *</th>
                                        <td>{item.phone_no}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">E-mail *</th>
                                        <td>{item.email}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Company Name/Website *</th>
                                        <td>{item.company_name}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Your position in the company *</th>
                                        <td>{item.your_position}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <h4 className="card-title mt-2 mb-2">Company Information :</h4>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-striped mb-0">
                                    <tbody>
                                    <tr>
                                        <th scope="row">Country Of interest *</th>
                                        <td>{item.country_of_interest}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Please, indicate if you are interested in other countries/regions</th>
                                        <td>{item.interest_in_other_countries}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Is this application a personal or corporate request? *</th>
                                        <td>{item.corporate_request}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">What is your main interest? *</th>
                                        <td>{item.main_interest}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Main Profile of your group?</th>
                                        <td>{item.main_profile_group}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">If "Other", please specify</th>
                                        <td>{item.others}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Current Liquidity Asset (Cash)</th>
                                        <td>{item.current_liquidity_asset}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">What is your capital to invest in the YOLE project?</th>
                                        <td>{item.capital_to_invest}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Do you or your company have experience in retail?</th>
                                        <td>{item.experience_in_retail}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Do you or your company have experience in F&B sector?</th>
                                        <td>{item.experience_in_fb_sector}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Do you or your company have experience in franchises?</th>
                                        <td>{item.company_experience_in_franchisee}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">How many units would you plan to expand in the next 5 years?</th>
                                        <td>{item.plan_to_extend}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">If you apply as a group/company, please describe your current business</th>
                                        <td>{item.describe_your_current_business}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">If you apply as Masterfranchise, we are interested to hear about your expansion plan</th>
                                        <td>{item.your_expansion_plan}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FranchiseeDetail;