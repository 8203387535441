import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import PropTypes from 'prop-types';
import axios from "../axios";

function Header({handleSidebarToggle}) {
    const logoutUser = ()=> {
        localStorage.removeItem('token');
        delete axios.defaults.headers.common['Authorization'];
        window.location.href = '/login';
    }

    return(
        <header id="page-topbar">
            <div className="navbar-header">
                <div className="d-flex">
                    <div className="navbar-brand-box">
                        <Link to="/dashboard" className="logo logo-dark">
                                <span className="logo-sm">
                                    <img src="../../assets/images/logo-sm.png" alt="" height="22" />
                                </span>
                            <span className="logo-lg">
                                    <img src="../../assets/images/yole-logo.svg" alt="" height="17" />
                                </span>
                        </Link>

                        <Link to="/dashboard" className="logo logo-light">
                                <span className="logo-sm">
                                    <img src="../../assets/images/logo-sm.png" alt="" height="22" />
                                </span>
                            <span className="logo-lg">
                                    <img src="../../assets/images/yole-logo.svg" alt="" height="40" />
                                </span>
                        </Link>
                    </div>

                    <button type="button"
                            className="btn btn-sm px-3 font-size-16 header-item waves-effect"
                            id="vertical-menu-btn"
                            onClick={handleSidebarToggle}
                    >
                        <i className="fa fa-fw fa-bars"></i>
                    </button>
                </div>

                <div className="d-flex">

                    <div className="dropdown d-inline-block d-lg-none ms-2">
                        <button type="button" className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="mdi mdi-magnify"></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                             aria-labelledby="page-header-search-dropdown">

                            <form className="p-3">
                                <div className="form-group m-0">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                                        <div className="input-group-append">
                                            <button className="btn btn-primary" type="submit"><i className="mdi mdi-magnify"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="dropdown d-inline-block">
                        <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img className="rounded-circle header-profile-user" src="../../assets/images/logo-sm.png"
                                 alt="Yole Avatar" />
                            <span className="d-none d-xl-inline-block ms-1" key="t-henry">Yolé</span>
                            <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-end">
                            {/*<a className="dropdown-item" href="#"><i className="bx bx-user font-size-16 align-middle me-1"></i> <span key="t-profile">Profile</span></a>*/}
                            {/*<div className="dropdown-divider"></div>*/}
                            <button className="dropdown-item text-danger" type="button" onClick={logoutUser}>
                                <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
                                <span key="t-logout">Logout</span>
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </header>
    );
}

export default Header;