import React, {useState} from "react";
import Header from "./Header";
import Nav from "./Nav";
import Footer from "./Footer";

function Layout({ children }){
    const [isShow, setIsShow] = useState(true); // State to toggle visibility
    const [hasMargin, setHasMargin] = useState(false); // State to toggle margin-left

    const handleSidebarToggle = () => {
        setIsShow(!isShow); // Toggle display
        setHasMargin(!hasMargin); // Toggle margin-left
    };

    return(
        <div id="layout-wrapper">
            <Header handleSidebarToggle={handleSidebarToggle} />
            <Nav isShow={isShow} />

            <div className="content-wrapper"
                 style={{
                    marginLeft: hasMargin ? '0' : '250px',
                }}>
                <div className="page-content">
                    {children}
                </div>

                <Footer />
            </div>
        </div>
    );
}

export default Layout;