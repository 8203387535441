import React, {useState} from "react";
import axios from "../../axios";
import {showToast} from "../ToastHelper";
import Loader from "../Loader";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function SorryMail({id, countryCode}) {
    const [showSorryModel, setShowSorryModel] = useState(false);
    const [customMessage, setCustomMessage] = useState('');
    const [error, setError] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [isMailSent, setIsMailSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const predefinedUnavailableCountryTemplate = `
        <p>Thank you for your interest in the Yolé brand. We have received your information and interest in opening a franchise store/s in your region.</p>
        <p>Unfortunately, the region selected is currently not open for new franchise. However, we will keep your record and contact you in the future when the opportunity arises.</p>
        <p>Thank you again for your time and interest.</p>
    `;

    const predefinedLowInvestmentTemplate = `
        <p>Thank you for your interest in the YOLE brand. We have received your information and interest in opening a franchise store/s in your region.</p>
        <p>Unfortunately, we are not open for more franchise opportunities under that investment selected. The minimum required is above your selection. However, we will keep your record and contact you in the future when the new opportunity arises.</p>
        <p>Thank you again for your time and interest.</p>
    `;

    const toggleModal = () => {
        setShowSorryModel(!showSorryModel);
        setError('');
        setResponseMessage('');
    };

    // Function to set the "Unavailable Country" template
    const setUnavailableCountryTemplate = () => {
        setCustomMessage(predefinedUnavailableCountryTemplate);
        // Set the predefined template when opening the modal
        if (!showSorryModel) {
            setCustomMessage(predefinedUnavailableCountryTemplate);
        } else {
            setCustomMessage('');
        }

        toggleModal(); // Open the modal after setting the template
    };

// Function to set the "Low Investment" template
    const setLowInvestmentTemplate = () => {
        setCustomMessage(predefinedLowInvestmentTemplate);
        // Set the predefined template when opening the modal
        if (!showSorryModel) {
            setCustomMessage(predefinedLowInvestmentTemplate);
        } else {
            setCustomMessage('');
        }

        toggleModal(); // Open the modal after setting the template
    };

    const handleSorrySubmit = async (e) => {
        e.preventDefault();

        if (!customMessage.trim()) {
            setError('Custom message field is required.');
            return;
        }

        try {
            setIsMailSent(true);
            setIsLoading(true);
            const response = await axios.post(`email/sorry/${id}`, {
                message: customMessage,
                country: countryCode
            });

            if (response.data.status === 'success') {
                showToast(response.data.message, 'success');
                toggleModal();
            }
        } catch (error) {
            showToast('Failed to send the email. Please try again.', 'error');
        }finally {
            setIsMailSent(false);
            setIsLoading(false);
        }
    };

    return(
        <>
            {isLoading && <Loader/>}
            {/*<button type="button" className="btn btn-sm btn-outline-primary"*/}
            {/*        style={{marginRight: '5px'}} onClick={toggleModal}>*/}
            {/*    Sorry*/}
            {/*</button>*/}

            <div className="dropdown d-inline-block" style={{marginRight: '5px'}}>
                <button className="btn btn-outline-secondary btn-sm btn-thick dropdown-toggle dropdown-arrow"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Sorry
                </button>
                <div className="dropdown-menu">
                    <button type="button" className="dropdown-item" onClick={setUnavailableCountryTemplate}>
                        Country Unavailable
                    </button>
                    <button type="button" className="dropdown-item" onClick={setLowInvestmentTemplate}>
                        Low Investment
                    </button>
                </div>
            </div>

            {/* Sorry Modal */}
            <div className={`modal fade ${showSorryModel ? 'show d-block' : ''}`} tabIndex="-1" role="dialog"
                 style={{display: showSorryModel ? 'block' : 'none'}}>
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Sorry Message</h5>
                            <button type="button" className="close" aria-label="Close" onClick={toggleModal}
                                    style={{backgroundColor: 'transparent', border: 'none', fontSize: '22px'}}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form onSubmit={handleSorrySubmit}>
                            <div className="modal-body">
                                <label htmlFor="textarea">Message</label>
                                <ReactQuill
                                    value={customMessage}
                                    onChange={setCustomMessage}
                                    theme="snow"
                                    placeholder="Write a message here..."
                                    style={{height: '300px'}}
                                />
                                {error && <div className="text-danger mt-2">{error}</div>}
                            </div>
                            <div className="modal-footer mt-5">
                                <button type="button" className="btn btn-secondary" onClick={toggleModal}>
                                    Close
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    {isMailSent ? 'Sending Mail...' : 'Send Message'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* Modal backdrop */}
            {showSorryModel && <div className="modal-backdrop fade show"></div>}
        </>
    )
}

export default SorryMail;