import React, {useState} from "react";
import axios from "../../axios";
import {showToast} from "../ToastHelper";
import Loader from "../Loader";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function ThankYouMail({id, countryCode}) {
    const [showThankYouModel, setShowThankYouModel] = useState(false);
    const [customMessage, setCustomMessage] = useState('');
    const [error, setError] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [isMailSent, setIsMailSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const predefinedTemplate = `
        <p>Thank you for your submission. We have successfully received your application!</p>
        <p>In order to get more familiar with our brand, please see more details in the presentation attached. You will find information about our value proposition, our products, offerings, and the different YOLE units. You will get a very good overview.</p>
        <p>After your review, if you find the YOLE brand an exciting opportunity to explore further and invest, we can share with you further information. Please, let me know if you are interested to know further.</p>
        <p>Looking forward to hearing from you</p>
    `;

    const toggleModal = () => {
        setShowThankYouModel(!showThankYouModel);
        setError('');
        setResponseMessage('');

        // Set the predefined template when opening the modal
        if (!showThankYouModel) {
            setCustomMessage(predefinedTemplate);
        } else {
            setCustomMessage('');
        }
    };

    const handleThankYouSubmit = async (e) => {
        e.preventDefault();

        if (!customMessage.trim()) {
            setError('Custom message field is required.');
            return;
        }

        try {
            setIsMailSent(true);
            setIsLoading(true);
            const response = await axios.post(`email/thankyou/${id}`, {
                message: customMessage,
                country: countryCode
            });

            if (response.data.status === 'success') {
                showToast(response.data.message, 'success');
                toggleModal();
            }
        } catch (error) {
            showToast('Failed to send the email. Please try again.', 'error');
        }finally {
            setIsMailSent(false);
            setIsLoading(false);
        }
    };

    return(
        <>
            {isLoading && <Loader />}
            <button type="button" className="btn btn-sm btn-outline-secondary btn-thick"
                    style={{marginRight: '5px'}} onClick={toggleModal}>
                Thank You
            </button>

            {/* Sorry Modal */}
            <div className={`modal fade ${showThankYouModel ? 'show d-block' : ''}`} tabIndex="-1" role="dialog" style={{ display: showThankYouModel ? 'block' : 'none' }}>
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Thank You Message</h5>
                            <button type="button" className="close" aria-label="Close" onClick={toggleModal} style={{backgroundColor: 'transparent', border: 'none', fontSize: '22px'}}>
                                <span aria-hidden="true"><i className="fa fa-window-close"></i></span>
                            </button>
                        </div>
                        <form onSubmit={handleThankYouSubmit}>
                            <div className="modal-body">
                                <label htmlFor="textarea">Message</label>
                                <ReactQuill
                                    value={customMessage}
                                    onChange={setCustomMessage}
                                    theme="snow"
                                    placeholder="Write a message here..."
                                    style={{ height: '300px' }}
                                />
                                {error && <div className="text-danger mt-2">{error}</div>}
                            </div>
                            <div className="modal-footer mt-5">
                                <button type="button" className="btn btn-secondary" onClick={toggleModal}>
                                    Close
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    {isMailSent ? 'Sending Mail...' : 'Send Message'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* Modal backdrop */}
            {showThankYouModel && <div className="modal-backdrop fade show"></div>}
        </>
    )
}

export default ThankYouMail;