import React, {useEffect, useState} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import axios from './axios';
import Login from "./pages/Login";
import Register from "./pages/Register";
import Layout from "./components/Layout";
import Dashboard from "./pages/Dashboard";
import Franchisee from "./pages/Franchisee/Franchisee";
import SpainFranchisee from "./pages/Franchisee/SpainFranchisee";
import FranchiseeDetail from "./pages/Franchisee/FranchiseeDetail";
import FranchiseeEdit from "./pages/Franchisee/FranchiseeEdit";
import Distributor from "./pages/Distributor/Distributor";
import DistributorDetail from "./pages/Distributor/DistributorDetail";
import DistributorEdit from "./pages/Distributor/DistributorEdit";
import FranchiseeSpainDetail from "./pages/Franchisee/FranchiseeSpainDetail";
import FranchiseeUk from "./pages/Franchisee/FranchiseeUk";
import FranchiseeUkDetail from "./pages/Franchisee/FranchiseeUkDetail";
import AquaFranchisee from "./pages/Aqua/AquaFranchisee";
import AquaFranchiseeUk from "./pages/Aqua/AquaFranchiseeUk";
import AquaFranchiseeSpain from "./pages/Aqua/AquaFranchiseeSpain";
import GoldFranchisee from "./pages/Gold/GoldFranchisee";
import GoldFranchiseeSpain from "./pages/Gold/GoldFranchiseeSpain";
import GoldFranchiseeUk from "./pages/Gold/GoldFranchiseeUk";

function App() {
    const [authenticated, setAuthenticated] = useState(false);
    const navigate = useNavigate();

    // Check if the user is already authenticated (e.g., with a stored token) on app load
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            setAuthenticated(true);
        } else {
            navigate('/login');
        }
    }, [navigate, authenticated]);

    return (
        <>
            {authenticated ? (
                <Layout>
                    <Routes>
                        {/*<Route path="/" element={ <Dashboard /> } />*/}
                        <Route path="/dashboard" element={ <Dashboard /> } />
                        <Route path="/franchisee" element={ <Franchisee /> } />
                        <Route path="/aqua-global-franchisee" element={ <AquaFranchisee /> } />
                        <Route path="/gold-global-franchisee" element={ <GoldFranchisee /> } />
                        <Route path="/franchisee/:id" element={ <FranchiseeDetail /> } />
                        <Route path="/franchisee/edit/:id" element={ <FranchiseeEdit /> } />
                        <Route path="/distributor" element={ <Distributor /> } />
                        <Route path="/distributor/:id" element={ <DistributorDetail /> } />
                        <Route path="/distributor/edit/:id" element={ <DistributorEdit /> } />
                        <Route path="/spain-franchisee" element={ <SpainFranchisee /> } />
                        {/*<Route path="/aqua-spain-franchisee" element={ <AquaFranchiseeSpain /> } />*/}
                        {/*<Route path="/gold-spain-franchisee" element={ <GoldFranchiseeSpain /> } />*/}
                        <Route path="/spain-franchisee/:id" element={ <FranchiseeSpainDetail /> } />
                        <Route path="/uk-franchisee" element={ <FranchiseeUk /> } />
                        {/*<Route path="/aqua-uk-franchisee" element={ <AquaFranchiseeUk /> } />*/}
                        {/*<Route path="/gold-uk-franchisee" element={ <GoldFranchiseeUk /> } />*/}
                        <Route path="/uk-franchisee/:id" element={ <FranchiseeUkDetail /> } />
                    </Routes>
                </Layout>
            ) : (
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                </Routes>
            )}
        </>
    );
}

export default App;
