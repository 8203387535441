import { toast } from 'react-toastify';

export const showToast = (message, type = 'success') => {
    const toastConfig = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        zIndex: 9999,
        style: {
            background: type === 'error' ? "#eb2a2d" : "#5cb85c", // Adjust colors as needed
            color: "#ffffff",
            border: type === 'error' ? "#fcc2c3" : "#4cae4c", // Adjust colors as needed
        },
        className: type === 'error' ? 'toast-error' : 'toast-success',
    };

    toast[type](message, toastConfig);
};